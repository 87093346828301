.app-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 100vh;
  align-items: center;
  justify-items: center;
  background: black;
}

.estimate-display-container {
  margin: 0;
  font-size: 46vw;
  user-select: none;
  font-weight: bold;
  color: white;
}

.btn-toggle-scale {
  position: fixed;
  right: 2vw;
  top: 2vh;
  -webkit-appearance: none;
  background: #757575;
  color: #FDFDFD;
  border: none;
  padding: 0.5em;
  font-size: 2vw;
  font-weight: bold;
}

.input-estimate-range {
  position: fixed;
  right: 2vw;
  bottom: 2vh;
  width: 96vw;
  -webkit-appearance: none;
  border: none;
  background: #757575;
  height: 4vh;
}

@media screen and (min-width: 800px) {
  .input-estimate-range {
    width: 50%;
  }
}

.input-estimate-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 4vh;
  width: 10%;
  background: #FDFDFD;
}

.input-estimate-range::-moz-range-thumb {
  height: 4vh;
  background: #FDFDFD;
}

.input-estimate-range::-ms-thumb {
  height: 4vh;
  background: #FDFDFD;
}

.visually-hidden { 
  position: absolute !important;
  height: 1px; 
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap; /* added line */
}